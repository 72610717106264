body {
  background-color: #343a40;
}
.CookieConsent a{
  color: rgb(245, 245, 75);
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

h2 {
  margin-bottom:50px;
}

.ac-container {
  background-color: whitesmoke;
  padding: 5%;
  border-radius: 5px;
  margin: 0 10vw;
}

.navbar--link-color:hover,
.navbar--link-color:focus {
  color: khaki !important;
}

.ac-container .ac-txt--contact {
  font-size: 1.3vm;
  margin: -40px auto 30px;
  background-color: rgb(238, 228, 228);
  padding: 0.8vw;
  border-left: 3px solid blue;
}

.ac-container--no-match {
  background: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  position: relative;
  display: block;
}

.ac-container--no-match::before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
}

.ac-container--no-match .ac-img--no-match {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ac-container--no-match .ac-txt--no-match {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2vw;
  text-align: center;
}

.ac-container--no-match .ac-btn--no-match {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2vw;
  text-align: center;
}

.footer {
  background-color: #343a40;
  max-width: 100%;
  margin: 50px 0;
  color: white;
  text-align: center;
}

.footer a {
	color: white;
}

.footer a:hover {
	color: khaki;
	text-decoration: none;
}

table {
  width: 85%;
  margin: auto;
  border-spacing: 0;
	border-collapse: collapse;
}

tr:nth-of-type(odd) {
	background: #d6d6d6;
}

tr:nth-of-type(even) {
	background: #fff;
}

th {
	background:#333;
	color: white;
	font-weight: bold;
	text-align: center !important;
}

td {
	font-size: 100%;
	line-height :1.5;
	font-family: Verdana;
	text-align: left;
}

td, th {
	padding: 6px;
	border: 1px solid #CCC;
}

@media not screen and (min-width:320px), (min-device-width: 320px) and (max-width: 663px), (max-device-width: 663px) {
	tr:nth-of-type(odd):hover { background: #d6d6d6; color: black; }
	tr:nth-of-type(even):hover { background: #FFF; color: black; }
}

@media screen and (min-width: 664px) and (max-width: 4000px), (min-device-width: 664px) and (max-device-width: 4000px) {
	th { text-align: center; }
	td:nth-child(1) { text-align: center; }
	td:nth-child(2) { text-align: center; }
	td:nth-child(3) { text-align: center; }
	td:nth-child(4) { text-align: center; }
	td:nth-child(5) { text-align: center; }
	td:nth-child(6) { text-align: center; }
}

@media screen and (max-width: 663px), (min-device-width: 320px) and (max-device-width: 663px) {
	table, thead, tbody, th, td, tr { display: block; }
	thead tr { position: absolute; top: -9999px; left: -9999px; }
	tr { border: 1px solid #CCC; }
	td { border: 1px solid #CCC; border-bottom: 1px solid #eee; position: relative; padding-left: 50%; }
	td:nth-child(1) { text-align: left; }
	td:nth-child(2) { text-align: left; }
	td:nth-child(3) { text-align: left; }
	td:nth-child(4) { text-align: left; }
	td:nth-child(5) { text-align: left; }
	td:nth-child(6) { text-align: left; }
	td:before { position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap; }
	td:nth-of-type(1):before { content: "DEC"; }
	td:nth-of-type(2):before { content: "Symbol"; }
	td:nth-of-type(3):before { content: "HTML Number"; }
	td:nth-of-type(4):before { content: "HTML Name"; }
	td:nth-of-type(5):before { content: "Windows ALT Key"; }
	td:nth-of-type(6):before { content: "Description"; }
}